// eslint-disable-next-line @typescript-eslint/naming-convention
export const CognitoConstants = {
  forgetPassword: {
    method: 'POST',
    endPoint: '/api/v1/users/user/forgot-password',
  },
  setPassword: {
    method: 'POST',
    endPoint: '/api/auth/updatePassword?_c=setPassword',
  },
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const ApiConstants = {
  search: {
    method: 'GET',
    endPoint: '/api/public/container/search/',
  },
  tracking: {
    method: 'POST',
    endPoint: '/api/claiming/page?_c=tracking',
  },
  details: {
    method: 'GET',
    endPoint: '/api/container-details/find/',
  },
  notes: {
    method: 'POST',
    endPoint: '/api/container-notes/update?_c=notes',
  },
  login: {
    method: 'POST',
    endPoint: '/api/v1/users/user-login?_c=login',
  },
  signup: {
    method: 'POST',
    endPoint: '/api/v1/users/user/user-signup/okta?_c=signup',
  },
  chart: {
    method: 'POST',
    endPoint: '/api/container-chart/find?_c=chart',
  },
  chartAvailability: {
    method: 'POST',
    endPoint: '/api/v1/status/multi/_search/trucker?_c=chartAvailability',
  },
  addContainer: {
    method: 'POST',
    endPoint: '/api/v1/container/claim?_c=addContainer',
  },
  intermodalClaim: {
    method: 'POST',
    endPoint: '/api/v1/container/claim-westbound?_c=intermodalClaim',
  },
  pendingContainers: {
    method: 'POST',
    endPoint: '/api/v1/container/pending-containers/persist?_c=pendingContainers',
  },
  removeContainer: {
    method: 'POST',
    endPoint: '/api/v1/container/unclaim?_c=removeContainer',
  },
  unisearch: {
    method: 'POST',
    endPoint: '/api/search?_c=unisearch',
  },
  fileUpload: {
    method: 'POST',
    endPoint: '/api/v1/container/add-file?_c=fileUpload',
  },
  download: {
    method: 'POST',
    endPoint: '/api/download/tracked-containers?_c=download',
  },
  feedback: {
    method: 'POST',
    endPoint: '/api/v1/cmc-service/feedback/send?_c=feedback',
  },
  containerPdf: {
    method: 'GET',
    endPoint: '/api/download/pdf/tracked-containers',
  },
  notificationSettings: {
    method: 'GET',
    endPoint: '/api/v1/cmc-service/notification/configurations',
  },
  notificationAlerts: {
    method: 'GET',
    endPoint: '/api/v1/cmc-service/alerts',
  },
  setNotificationAlerts: {
    method: 'POST',
    endPoint: '/api/v1/cmc-service/alerts?_c=setNotificationAlerts',
  },
  interestedParties: {
    method: 'GET',
    endPoint: '/api/v1/users/user/interested-parties',
  },
  markAsRead: {
    method: 'POST',
    endPoint: '/api/v1/cmc-service/notifications/mark-as-read?_c=markAsRead',
  },
  turnTimes: {
    method: 'GET',
    endPoint: '/api/terminal/turn-times/',
  },
  totalTurnTimes: {
    method: 'POST',
    endPoint: '/api/terminal/turn-times?_c=totalTurnTimes',
  },
  terminalWaitTime: {
    method: 'POST',
    endPoint: '/api/v1/turntime/_search?_c=terminalWaitTime',
  },
  terminalList: {
    method: 'GET',
    endPoint: '/api/v1/terminal/list',
  },
  terminalListStatus: {
    method: 'POST',
    endPoint: '/api/v1/vessel/_search/trucker?_c=terminalListStatus',
  },
  chassisCount: {
    method: 'POST',
    endPoint: '/api/v1/terminal-details/?_c=chassisCount',
  },
  updateCustomFields: {
    method: 'POST',
    endPoint: '/api/claiming/custom-fields?_c=updateCustomFields',
  },
  updateFlag: {
    method: 'POST',
    endPoint: '/api/claiming/flag/?_c=updateFlag',
  },
  filteredHoldCodes: {
    method: 'POST',
    endPoint: '/api/hold-codes/filtered-description?_c=filteredHoldCodes',
  },
  emptiesReceiving: {
    method: 'GET',
    endPoint: '/api/terminal/ebr/',
  },
  emptiesReturn: {
    method: 'POST',
    endPoint: '/api/v1/EBR?_c=emptiesReturn',
  },
  emptiesReturnSearch: {
    method: 'POST',
    endPoint: '/api/v1/EBR_Container',
  },
  emptiesReturnPublic: {
    method: 'POST',
    endPoint: '/api/v1/public-search/EBR?_c=emptiesReturnPublic',
  },
  gateHours: {
    method: 'POST',
    endPoint: '/api/v1/gate_hour/?_c=gateHours',
  },
  pinnedNotificationTypes: {
    method: 'GET',
    endPoint: '/api/v1/cmc-service/notifications/pinned-notification-types',
  },
  getAllUser: {
    method: 'GET',
    endPoint: '/api/v1/users/user/all',
  },
  vesselList: {
    method: 'POST',
    endPoint: '/api/vessel/page?_c=vesselList',
  },
  userPreferences: {
    method: 'POST',
    endPoint: '/api/v1/pott/pott-user-preferences/persist?_c=userPreferences',
  },
  landingPage: {
    method: 'POST',
    endPoint: '/api/v1/pott/pott-landing-page/persist?_c=landingPage',
  },
  customFields: {
    method: 'POST',
    endPoint: '/api/v1/pott/org-preferences/custom-fields/persist?_c=customFields',
  },
  acknowledgeRejectedContainers: {
    method: 'POST',
    endPoint: '/api/v1/container/pending/ack-rejected-containers?_c=acknowledgeRejectedContainers',
  },
  alertTypes: {
    method: 'GET',
    endPoint: '/api/v1/cmc-service/alerts/types',
  },
  notificationCategories: {
    method: 'GET',
    endPoint: '/api/v1/cmc-service/notifications/limited-categories',
  },
  notifications: {
    method: 'POST',
    endPoint: '/api/v1/cmc-service/notifications/limited-search?_c=notifications',
  },
  archiveNotifications: {
    method: 'POST',
    endPoint: `/api/v1/cmc-service/notifications/archive?_c=archiveNotifications`,
  },
  containerAvailabilityCount: {
    method: 'POST',
    endPoint: '/api/v1/vessel/_search/trucker?_c=containerAvailabilityCount',
  },
  containerHoldsCount: {
    method: 'POST',
    endPoint: '/api/v1/status/multi/_search/trucker?_c=containerHoldsCount',
  },
  newVesselList: {
    method: 'POST',
    endPoint: '/api/v1/status/multi/_search/trucker?_c=newVesselList',
  },
  containerStatusList: {
    method: 'POST',
    endPoint: '/api/v1/container_details/trucker?_c=containerStatusList',
  },
  terminalDetails: {
    method: 'POST',
    endPoint: '/api/v1/getTerminalDetails',
  },
  specialHandling: {
    method: 'GET',
    endPoint: '/api/v1/special-handling/all',
  },
  whatsNewPopupClose: {
    method: 'GET',
    endPoint: '/api/v1/users/feature/user-seen/disable',
  },
  cancelRequest: {
    method: 'POST',
    endPoint: '/api/v1/special-handling/cancelRequest',
  },
  newChart: {
    method: 'POST',
    endPoint: '/api/v1/status/_search/trucker?_c=newChart',
  },
  retrievePendingContainers: {
    method: 'GET',
    endPoint: '/api/v1/container/pending-containers/retrieve/data',
  },
  updatePendingContainers: {
    method: 'POST',
    endPoint: '/api/v1/container/pending-containers/update?_c=updatePendingContainers',
  },
  customCodes: {
    method: 'POST',
    endPoint: '/api/v1/custom_codes?_c=customCodes',
  },
  newContainerListExcelDownload: {
    method: 'POST',
    endPoint: '/api/v1/excel-download/container_details/trucker?_c=newContainerListExcelDownload',
  },
  newPDFDownload: {
    method: 'POST',
    endPoint: '/api/v1/pdf-download/trucker?_c=newPDFDownload',
  },
  newUniversalSearch: {
    method: 'POST',
    endPoint: '/api/v1/vessel/multi/_search/trucker?_c=newUniversalSearch',
  },
  newUpdateCustomFields: {
    method: 'POST',
    endPoint: '/api/v1/container/user/custom-field?_c=newUpdateCustomFields',
  },
  emptiesExcelDownload: {
    method: 'POST',
    endPoint: '/api/v1/excel/ebr?_c=emptiesExcelDownload',
  },
  newInterestedParties: {
    method: 'GET',
    endPoint: '/api/v1/users/list-users',
  },
  newLandingSearch: {
    method: 'GET',
    endPoint: '/api/v1/public-search',
  },
  emptiesHistorical: {
    method: 'POST',
    endPoint: '/api/v1/empties/historical?_c=emptiesHistorical',
  },
  emptiesHistoricalDownload: {
    method: 'POST',
    endPoint: '/api/v1/empties/historical-download-zip?_c=emptiesHistoricalDownload',
  },
  emptiesHistoricalTerminalList: {
    method: 'POST',
    endPoint: '/api/v1/EBR/_search?_c=emptiesHistoricalTerminalList',
  },
  intermodalList: {
    method: 'POST',
    endPoint: '/api/v1/export/westbound/_search?_c=intermodalList',
  },
  intermodalContainerList: {
    method: 'POST',
    endPoint: '/api/v1/export/westbound_container?_c=intermodalContainerList',
  },
  intermodalContainersUnclaim: {
    method: 'POST',
    endPoint: '/api/v1/container/unclaim-westbound?_c=intermodalContainersUnclaim',
  },
  intermodalCustomFieldsUpdate: {
    method: 'POST',
    endPoint: '/api/v1/container/export/user/custom-field?_c=intermodalCustomFieldsUpdate',
  },
  intermodalContainerListDownload: {
    method: 'POST',
    endPoint: '/api/v1/excel-download/westbound',
  },
  historicalTripDetails: {
    method: 'POST',
    endPoint: '/api/v1/search/containerHistoricDetails',
  },
  containerHistoricData: {
    method: 'POST',
    endPoint: '/api/v1/search/containerHistoricData',
  },
  requestSpecialHandling: {
    method: 'POST',
    endPoint: '/api/v1/special-handling/createRequest',
  },
  whatsNewArchieve: {
    method: 'GET',
    endPoint: '/api/v1/users/feature/archived/5',
  },
  whatsNewLatest: {
    method: 'GET',
    endPoint: '/api/v1/users/feature/latest',
  },
  turnTimeAverages: {
    method: 'POST',
    endPoint: '/api/v1/ct-search/signal_turntime/multi/_search?_c=turnTimeAverages',
  },
  universalAppointmentSystemScacPopup: {
    method: 'GET',
    endPoint: '/api/v1/container/uas/popup_enable',
  },
  universalAppointmentSystemValidateScac: {
    method: 'POST',
    endPoint: '/api/v1/container/uas/validate_scac',
  },
};
