import packageInfo from '../../package.json';

export const environment = {
  production: true,
  version: packageInfo.version,
  apiEndpointUrl: '',
  cognitoEndpointUrl: '',
  gaTrackingId: 'UA-98834924-7',
  fullStoryOrgID: 'o-1AGG19-na1',
  timeZone: 'America/Los_Angeles',
  uasAuthentication:{
    publicKey:'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAq+6qWKB6qKZppJhwdAsvL4XW5OGwigIrZ9x69zW4jgemqRO9QOjEOSWnHErKTxVHTrH6w5+BKjj28hepA6xcUJGD37fmD6WeF7Nf7LamnEADWn1u/S/SOUbIHGugXHR0SkJee0R8VBdebyQehAx5gpsPA9jLoumzOuV0BL4pdF4gw4nprfGrL3eOIcLJ9Z5yD3iA0L2bKUe2QwrTIb1Mc+a9TTnEOd7KJ+NLXCYLOPWzdTqxWbq2UkV7RWVyleAAWZVIfIRMIBxKofNCSLk/aeFOEexP3KwFUJ8mGvGVQC1pKGWjGrn3SZ1c9VBzD3DEvN4GnbKO41s0WTrCSxn+ywIDAQAB', /* should use in production */
    uasPortURl:'https://uas.draydog.com/?view=book',
    userInfoEndPoint:'/api/v1/admin/uas/user-details',
    uasPostUrlMessage:'https://uas.draydog.com'
  },
  appointmentsOrgIds: [
    // '5f5b3b3b-0b3b-4b3b-8b3b-0b3b3b3b3b3b', // Random UUID
    'e7a160bf-4552-4d73-84a6-a2b61e1bb18e', // Anshul UUID
    // '081ed9ae-6728-11e8-b8c1-a79c98051cc8', // Wabtec Org Id
    'cc90d68b-f1f9-4539-b11d-0c861c33c841', // Dinesh Dulla UUID
    'f3eeee23-5f55-44ef-94cc-024034f51d1f', // Deepak Sahoo UUID
    'ac77360b-8fdc-4492-b30a-a3b5abb43b65', // Sadhu Narayana UUID
    '2bc94892-3040-45c1-854a-02c92ae355ce', // Abhimanyu Acharya UUID
  ],
  features: {
    notifications: true,
    terminalPage: true,
    clickCountModal: true,
    timeReachModal: true,
    vesselPage: true,
    appointmentsPage:true,
    userPreferences: true,
    helpMenu: true,
    appointmentField: true,
    notificationSidebar: true,
    emptiesPage: true,
    historyPage: true,
    emptiesHistorical: true,
    acknowledgeContainers: true,
    newVesselList: true,
    newContainerStatusList: true,
    newChart: true,
    appendPendingContainers: true,
    updatePendingContainers: true,
    enableClaimBOL: true,
    newContainerListDownload: true,
    appointmentSchedule: true,
    newInterestedParties: false,
    universalSearchV2: false,
    useCatoResponse: true,
    newLandingSearch: true,
    intermodalPage: false,
    registerWithSCAC: true,
    hideDownloadPDF: true,
    whatsNewPopUp: true,
    whatsNewPopUpSideBar: true,
    emptyReturnsSearch:true,
  },
  oidc: {
    issuer: 'https://wabtec.oktapreview.com/oauth2/ausrjhvj8rcoY6A4Z0h7',
    clientId: '0oa1qeyrfklPz45Up0h8',
    redirectUri: window.location.origin +'/login/callback',
    postLogoutRedirectUri: window.location.origin,
    scopes: ['openid', 'profile', 'email', 'offline_access'],
    pkce: true,
    tokenManager: {
      autoRenew: false,
      autoRemove: false,
      storage: 'sessionStorage',
    },
  },
};
