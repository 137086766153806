import { ISidebarMenu } from '@models/dashboard/sidebar.models';

export const menuOptions: ISidebarMenu[] = [
  {
    parent: 'IMPORTS',
    children: [
      {
        title: 'Containers',
        value: 'container',
        imageSource: '/assets/svgs/container.svg',
        imageSourceActive: '/assets/svgs/container-active.svg',
        children: [],
        url: '/container/overview',
        expanded: false,
        selected: false,
        isAllowed: true,
        isChildActive: false,
      },
      {
        title: 'Vessels',
        value: 'vessel',
        imageSource: '/assets/svgs/vessel.svg',
        imageSourceActive: '/assets/svgs/vessel-active.svg',
        children: [
          { title: 'details', url: '/vessel/details', isAllowed: false },
        ],
        url: '/vessel/overview',
        expanded: false,
        selected: false,
        isAllowed: false,
        isChildActive: false,
      },
      {
        title: 'Terminals',
        value: 'terminal',
        imageSource: '/assets/svgs/terminal.svg',
        imageSourceActive: '/assets/svgs/terminal-active.svg',
        children: [
          { title: 'details', url: '/terminal/details', isAllowed: false },
        ],
        url: '/terminal/overview',
        expanded: false,
        selected: false,
        isAllowed: false,
        isChildActive: false,
      },
      {
        title: 'Empty Returns',
        value: 'empties',
        imageSource: '/assets/svgs/icon-empty-grey.svg',
        imageSourceActive: '/assets/svgs/icon-empty-active.svg',
        children: [
          { title: 'historical', url: '/terminal/empties-historical', isAllowed: false },
        ],
        url: '/terminal/empties',
        expanded: false,
        selected: false,
        isAllowed: false,
        isChildActive: false,
      },
      {
        title: 'Appointments',
        value: 'appointments',
        imageSource: '/assets/svgs/icon-history-grey.svg',
        imageSourceActive: '/assets/svgs/icon-history-active.svg',
        children: [
          { title: 'Appointments', url: '/appointments/overview', isAllowed: false },
        ],
        url: '/appointments/overview',
        expanded: false,
        selected: false,
        isAllowed: false,
        isChildActive: false,
      },
       {
        title: 'Historical Search',
        value: 'history',
        imageSource: '/assets/svgs/icon-history-grey.svg',
        imageSourceActive: '/assets/svgs/icon-history-active.svg',
        children: [
          { title: 'historySearch', url: '/terminal/historical-search', isAllowed: false },
        ],
        url: '/terminal/historical-search',
        expanded: false,
        selected: false,
        isAllowed: false,
        isChildActive: false,
      },
    ],
  },
  {
    parent: 'EXPORTS',
    children: [
      {
        title: 'Intermodal',
        value: 'intermodal',
        imageSource: '/assets/svgs/intermodal.svg',
        imageSourceActive: '/assets/svgs/intermodal-active.svg',
        imageWidth: '24px',
        children: [
          { title: 'details', url: '/intermodal/details', isAllowed: false },
        ],
        url: '/intermodal/overview',
        expanded: false,
        selected: false,
        isAllowed: false,
        isChildActive: false,
      },
    ],
  },
];

export enum menuNamesEnum {
  CONTAINERS = 'Containers',
  VESSEL = 'Vessels',
  TERMINAL = 'Terminals',
  INTERMODAL = 'Intermodal',
  SCHEDULING = 'Scheduling',
  OVERVIEW = 'Overview',
  EMPTIES_RETURN = 'Empty Returns',
  APPOINTMENTS = 'Appointments',
  HISTORY_SEARCH = 'Historical Search',
  EMPTIES = 'Empties',
  DETAILS = 'details'
}

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
