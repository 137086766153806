import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  public featureEnabled(featureName: string): boolean {
    if (environment.features) {
      if (environment.features[featureName]) {
        return environment.features[featureName];
      }
    }

    return false;
  }
}
