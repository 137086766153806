import { Component, EventEmitter, OnDestroy, OnInit, Output, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { FeatureFlagService } from '@services/feature-flag.service';

import { menuNamesEnum, menuOptions } from '@models/shared/landing-page.constants';
import * as LandingPageAction from '@store/landing-page-settings/landing-page-settings.actions';
import * as fromLandingPage from '@store/landing-page-settings/landing-page-settings.reducer';
import { LandingPageSettingsState } from '@store/landing-page-settings/landing-page-settings.state';

import { IUserPreferenceModalMessage, ModalTypes } from '@models/shared/user-preferences.model';
import { environment } from '@env/environment';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-landing-page',
  templateUrl: './landing-page-settings.component.html',
  styleUrls: ['./landing-page-settings.component.css'],
})
export class LandingPageSettingsComponent implements OnInit, OnDestroy {
  @Output() public closeEvent = new EventEmitter<void>();
  @Output() public applyButtonStatusEvent = new EventEmitter<IUserPreferenceModalMessage>();

  private subscriptions: Subscription = new Subscription();

  public readonly features = {
    terminal: this.featureFlags.featureEnabled('terminalPage'),
    vessel: this.featureFlags.featureEnabled('vesselPage'),
    intermodal: this.featureFlags.featureEnabled('intermodalPage'),
    scheduling: this.featureFlags.featureEnabled('schedulingPage'),
    empties: this.featureFlags.featureEnabled('emptiesPage'),
    history: this.featureFlags.featureEnabled('historyPage'),
    appointments: this.featureFlags.featureEnabled('appointmentsPage'),
  };
  public landingPageSetting: FormGroup;
  public pages: string[] = [];
  public selectedPage: string;
  public error$: Observable<any>;
  public selectedAccordion: string;
  public formDirty = false;

  constructor(
    private fb: FormBuilder,
    private store: Store<LandingPageSettingsState>,
    private featureFlags: FeatureFlagService,
  ) {
  }

  public ngOnInit() {
    const storedPage = localStorage.getItem('landingPage');
    if (!!storedPage && (storedPage === 'undefined' || storedPage === null)) {
      localStorage.setItem('landingPage', 'Containers');
    }
    this.subscriptions.add(
      this.store.pipe(
        select(fromLandingPage.getSelectedLandingPage))
        .subscribe(page => {
            this.selectedPage = page ? page : localStorage.getItem('landingPage');
          },
        ),
    );

    this.landingPageSetting = this.fb.group({
      page: [this.selectedPage],
    });

    this.error$ = this.store.pipe(select(fromLandingPage.getErrorData));

    menuOptions[0].children.forEach(mainMenu => {
      if (mainMenu.children.length > 0) {
        mainMenu.children.forEach(childMenu => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
          const title = childMenu.title === menuNamesEnum.OVERVIEW || menuNamesEnum.DETAILS
            ? mainMenu.title
            : '';
          // Check if the menu item is 'Appointments' and if it should be hidden
          if (childMenu.title !== 'Appointments' || this.isAppointmentsEnabled(this.getUserData())) {
            this.pages.push(title);
          }
        });
      } else {
        // Check if the main menu item is 'Appointments' and if it should be hidden
        if (mainMenu.title !== 'Appointments' || this.isAppointmentsEnabled(this.getUserData())) {
          this.pages.push(mainMenu.title);
        }
      }
    });

    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    this.landingPageSetting.valueChanges.subscribe(_ => {
      this.formDirty = true;
      this.applyButtonStatusEvent.next({
        from: ModalTypes.LANDING,
        status: true,
      });
    });
  }

  // Assume we have a function to get user data
  public getUserData() {
    const userPreferencesData = localStorage.getItem('userID');
    return userPreferencesData ? userPreferencesData : null;
  }

  // Function to check if appointments should be enabled
  public isAppointmentsEnabled(userId: string): boolean {
    return environment.appointmentsOrgIds.includes(userId);
  }

  public initForm = () => {
    this.formDirty = false;
  }

  public onRadioClick = () => {
    this.formDirty = true;
  }

  public onCancelClick() {
    this.store.dispatch(new LandingPageAction.GetLandingPageSettingsReset());
    this.closeEvent.next();
  }

  public submitChanges() {
    this.selectedPage = this.landingPageSetting.get('page').value;
    localStorage.setItem('landingPage', this.selectedPage);
    this.store.dispatch(new LandingPageAction.GetLandingPageSettings({ 'landingPage': this.selectedPage }));
    this.closeEvent.emit();
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}