import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataLoadingService {
  private dataLoadedSubject = new BehaviorSubject<boolean>(false);

  setDataLoaded(loaded: boolean): void {
    this.dataLoadedSubject.next(loaded);
  }

  isDataLoaded(): Observable<boolean> {
    return this.dataLoadedSubject.asObservable();
  }
}