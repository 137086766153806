import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UniversalAppointmentSystemService } from '@app/core/services/universal-appointment-system.service';
import { ISelectedItems } from '@app/shared/models/dashboard/filters.model';
import { getSelectedList } from '@app/store/dashboard/dashboard.selectors';
import { select, Store } from '@ngrx/store';
import { Subscription, tap } from 'rxjs';

@Component({
  selector: 'app-schedule-appointment-signup-modal',
  templateUrl: './schedule-appointment-signup-modal.component.html',
  styleUrls: ['./schedule-appointment-signup-modal.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleAppointmentSignupModalComponent implements OnInit {
  @Output() public closeForm = new EventEmitter<void>();
  @Output() public submitData = new EventEmitter<object>();
  public signupForm: FormGroup;
  public apiError: string = '';
  public readonly parent: string = 'container';
  public selectedList: ISelectedItems = {
    selected: [],
    isAll: true,
    selectedPendingContainers: [],
    selectedContainerData: [],
    requestSpecialList: [],
  };
  private subscriptions: Subscription = new Subscription();

  constructor(
    private fb: FormBuilder,
    private store: Store<{}>,
    private universalAppointmentService: UniversalAppointmentSystemService,
    public dialogRef: MatDialogRef<ScheduleAppointmentSignupModalComponent>,
    private router: Router,
  ) {
    this.signupForm = this.fb.group({
      companyName: ['', Validators.required],
      scacCode: [
        '',
        [Validators.required, Validators.minLength(4), Validators.maxLength(4), Validators.pattern(/^[A-Z]{4}$/)],
      ],
      apiError: [''], // New form control for API error
    });
  }

  public ngOnInit() {
    this.signupForm.get('scacCode')?.valueChanges.subscribe((value) => {
      if (value) {
        const sanitizedValue = value
          .replace(/[^A-Za-z]/g, '')
          .toUpperCase()
          .slice(0, 4);
        if (sanitizedValue !== value) {
          this.signupForm.get('scacCode')?.setValue(sanitizedValue, { emitEvent: false });
        }
      }
      // Clear API error when user starts typing again
      if (this.signupForm.get('apiError')?.value) {
        this.signupForm.get('apiError')?.setValue('');
      }
    });

    this.subscriptions.add(
      this.store
        .pipe(
          select(getSelectedList, { parent: this.parent }),
          tap((selected) => (this.selectedList = selected)),
        )
        .subscribe(),
    );
  }

  public closeModal(): void {
    this.dialogRef.close();
    this.closeForm.emit();
  }

  public onSubmit(): void {
    if (this.signupForm.valid) {
      const formData = this.signupForm.value;
      const payload = {
        scac: formData.scacCode,
        companyName: formData.companyName,
      };

      this.universalAppointmentService.universalAppointmentSystemValidateScac(payload).subscribe({
        next: (response) => {
          if (response.validSCAC) {
            this.router.navigateByUrl('/appointments/overview');
            this.closeForm.emit();
          } else {
            this.signupForm.get('apiError')?.setValue('Invalid SCAC code. Please enter a valid SCAC code.');
            this.signupForm.get('scacCode')?.setErrors({ apiError: true });
          }
        },
        error: (error) => {
          this.signupForm.get('apiError')?.setValue('Invalid SCAC code. Please enter a valid SCAC code.');
          this.signupForm.get('scacCode')?.setErrors({ apiError: true });
        },
      });
    }
  }

  public get isFormValid(): boolean {
    return this.signupForm.valid;
  }
}
