<div class="schedule-signup">
  <div class="schedule-signup-content">
    <div class="schedule-signup-info">
      To schedule an appointment you need to confirm your <b>Company Name</b> 
      (as registered with the California Clean Truck Program) and your 
      valid <b>SCAC code</b> below:
    </div>
    <form [formGroup]="signupForm" (ngSubmit)="onSubmit()" class="schedule-signup-form">
      <mat-label class="schedule-label">Company Name </mat-label>
      <mat-form-field appearance="fill" class="signup-field">
        <input matInput formControlName="companyName" required>
        <mat-error *ngIf="signupForm.get('companyName')?.hasError('required')" class="signup-error">
          Company Name is required
        </mat-error>
      </mat-form-field>

      <mat-label class="schedule-label">SCAC Code </mat-label>
      <mat-form-field appearance="fill" class="signup-field">
        <input matInput formControlName="scacCode" required>
        <mat-error *ngIf="signupForm.get('scacCode')?.hasError('required')" class="signup-error">
          SCAC Code is required
        </mat-error>
        <mat-error *ngIf="signupForm.get('scacCode')?.hasError('pattern')" class="signup-error">
          SCAC Code must be 4 uppercase letters
        </mat-error>
        <mat-error *ngIf="signupForm.get('scacCode')?.hasError('apiError')" class="signup-error">
          {{ signupForm.get('apiError')?.value }}
        </mat-error>
      </mat-form-field>
      <div class="button-section">
        <button mat-stroked-button color="primary" type="button" class="cancel-signup" (click)="closeModal()">
          Cancel
        </button>
        <button mat-raised-button color="primary" type="submit" class="submit-signup" [disabled]="!isFormValid">
          Confirm
        </button>
      </div>
    </form>
  </div>
</div>